import React, { Component } from 'react';
import "react-toggle/style.css";

import { Navbar, Nav, FormControl, InputGroup } from 'react-bootstrap';

class Navigation extends Component {

  state = {
    keyword: ''
  }

  handleChange(e){
    // aggiorno lo stato
    this.setState({keyword: e.target.value});
    this.props.keyword(e.target.value);
  }

  render() {

    return(
        <Navbar bg="dark" variant="dark" id="navigation-bar">
          <Navbar.Brand>
            <img alt="dglen" className="brand" src={require('../../Assets/images/logo_wh.png')} />
          </Navbar.Brand>
          <Nav className="mr-auto"></Nav>

          <InputGroup.Prepend className="col-lg-10">
            <InputGroup.Text id="basic-addon1">
              <i className="fas fa-search"></i>
            </InputGroup.Text>
            <FormControl type="text" placeholder="Cerca" className="mr-sm-2 w-75" onChange={(e) => this.handleChange(e)} />
          </InputGroup.Prepend>
        </Navbar>
    )

  }

}

export default Navigation