import React, { Component } from 'react';

import { config } from '../../Config'
import AuthLib from '../../Utilities/AuthLib'

import { Container, Row, Col } from 'react-bootstrap';
import Navigation from '../../Common/Navigation'
import SideNav from '../../Common/SideNav'
import RequestsList from '../../Components/RequestsList'

class RequestScreen extends Component {

  constructor(props){
    super(props)

    this.state = {
      is_loading: false,
      requests: []
    }
  }
    // TODO: Inserire la funzione in una classe Utils e richiamarla andando a cambiare un parametro
    getList = async () => {
      this.setState({is_loading:true})
      // If the user has a valid token, he can see informations. Otherwise he will be kicked
      const auth = await AuthLib.checkAuth();
      
      if(auth === true){
        this.setState({is_loading:true})
        const response = await fetch(config.APIUrl + 'API/requests', {
          method: 'GET',
          headers: {
            'content-type' : 'application/json; charset=utf-8',
            'Authorization' : localStorage.getItem('token')
        },
    });
  
      const list = await response.json()
      this.setState({requests: list}) 
      } else {
      // Redirect to login users without authorization
      this.props.history.push('/')
    }
    this.setState({is_loading: false})
  }

  componentDidMount(){
    if(!AuthLib.checkAuth()){
      this.props.history.push('/')
    }
    this.getList();
  }

  render(){
    return(
      <div>
          <Navigation 
            keyword={this.handleKeyword}
          />
          <Container fluid={true}>
            <Row>
              <Col className="h-100" xs={6} md={2}>
                <SideNav />
              </Col>
              <Col className="h-100" xs={12} md={10}>
                <RequestsList is_loading={this.state.is_loading} requests={this.state.requests} handlerefresh={this.getList} />
              </Col>
            </Row>
          </Container>
        </div>
    )
  }

}

export default RequestScreen