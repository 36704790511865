import React, { Component } from 'react';

import {Table, Spinner} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';

import { config } from '../../Config';
import AuthLib from '../../Utilities/AuthLib'
import AppUtils from '../../Utilities/AppUtils'
import VerticalModal from '../../Components/Modal';

class LeadsList extends Component {

  state = {
      modalShow: false,
      is_loading: this.props.is_loading,
      lead: {}
  };

  componentWillReceiveProps(nextProps){
    this.setState({is_loading:nextProps.is_loading})
  }
  
  modalClose = () => {
    this.setState({ modalShow: false });
    this.setState({ lead: false });
  }

  clearState(){
    this.setState({ lead: false });
  }

  async editLead(id){
    const auth = await AuthLib.checkAuth();
    // If the user has a valid token, he can see the informations. Otherwise he will be kicked
    if(auth === true){
      this.setState({ modalShow: true })
      const response = await fetch(config.APIUrl + 'API/leads/' + id, {
        method: 'GET',
        headers: {
          'content-type' : 'application/json; charset=utf-8',
          'Authorization' : localStorage.getItem('token')
        },
      });
      const lead = await response.json()
      this.setState({ lead: lead })
    } else{
      // Kick users without authorization
      this.props.history.push('/')
    }

  }

  render(){

    return(
      <div>
        <div className="mr-5 ml-2 mb-4">
          <h4 id="leads-primary" className="mt-4">Lista Leads</h4>
          <h6 className="text-secondary">Richieste provenienti dalle landing page</h6>
        </div>

        <Table className="mr-2 ml-2 responsive">
          <thead>
            <tr>
              <th>Status</th>
              <th>Email</th>
              <th>Landing</th>
              <th>Data</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.is_loading
              ?
              <tr>
                <td></td>
                <td>
                  <Spinner className="mx-auto d-block" animation="border" variant="info" />
                </td>
                <td></td>
              </tr>
                :
                this.props.leads.length > 0 && !this.state.is_loading
                ?
                this.props.leads.map((item, index) => {
                  let date = new Date(item.date);
                  return (
                    <tr key={index}>
                      <td style={{color: AppUtils.checkColor(item.status)}}>{item.status}</td>
                      <td>{item.email}</td>
                      <td>{item.landing_name}</td>
                      <td>{date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}</td>
                      <td><i onClick={() => this.editLead(item.id)} id="leads-link" className="fas fa-pen"></i></td>
                    </tr>
                  )
                })
                :
                <tr>
                  <td></td>
                  <td className="pt-3">Leads non trovati</td>
                  <td></td>
                </tr>
            }
          </tbody>
        </Table>
        

        <VerticalModal
          handlerefresh={this.props.handlerefresh}
          show={this.state.modalShow}
          onHide={this.modalClose}
          lead={this.state.lead}
          clearState={this.clearState}
        />

      </div>
    )

  }
  
}

export default withRouter(LeadsList);