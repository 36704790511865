import React, { Component } from 'react';

import {config} from '../../Config'

import { Container, Row, Col, Form, Button, InputGroup, Spinner } from 'react-bootstrap';

class AuthScreen extends Component {

  state = {
    email: '',
    password: '',
    is_loading: false,
    error_message: '',
    display_errors: false
  }

  async doLogin(e){
    e.preventDefault();
    this.setState({is_loading: true})
    this.setState({display_errors: false})

    await fetch(config.APIUrl + 'API/auth', {
      method: 'POST',
      headers: {
        'content-type' : 'application/json; charset=utf-8'
      },
      body: JSON.stringify({'email' : this.state.email, 'password' : this.state.password })
    })
    .then(response => response.json())
    .then(data => {
      if(data.token){
        localStorage.setItem('token', data.token);
        localStorage.setItem('email', this.state.email);

        this.setState({is_loading: false})
        this.props.history.push('/leads');
  
        
      } else{
        this.setState({display_errors: true})
        this.setState({error_message: 'Username o password errati.'})
        this.setState({is_loading: false})
      }
    })
    .catch(error => {
      console.log(error)
    })

  }

  render(){

    return(
      <div>
        <div id="background"></div>
        <Container>
          <Row>
            <Col md={6} id="AuthForm" className="mx-auto p-4 mt-5">
            {
              this.state.display_errors === true
              ?
              <div className="alert alert-danger">
                {this.state.error_message}
              </div>
              :
              null
            }
            
              <img alt="dglen" className="m-auto d-block" src={require('../../Assets/images/logo.png')} style={{height:'70px'}}/>
              <br></br>
              <Form className="auth-form">
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="bg-transparent" id="basic-addon1"><i className="fas fa-user"></i></InputGroup.Text>
                  </InputGroup.Prepend>
                    <Form.Control className="h5" type="email" placeholder="Email" onChange={(e) => this.setState({email:e.target.value})} />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="bg-transparent" id="basic-addon1"><i className="fas fa-lock"></i></InputGroup.Text>
                  </InputGroup.Prepend>
                    <Form.Control className="h5" type="password" placeholder="Password" onChange={(e) => this.setState({password: e.target.value})} />
                </InputGroup>
                {
                  this.state.is_loading 
                  ?
                  <Spinner className="mx-auto d-block" animation="border" variant="info" />
                  :
                  <Button className="w-100 p-1 auth-button" variant="primary" type="submit" onClick={(e) => this.doLogin(e)}>
                    Log in
                  </Button>
                }
                
              </Form>
              
            </Col>
          </Row>
        </Container>
      </div>
    )

  }

}

export default AuthScreen