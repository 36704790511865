import React, { Component } from 'react';
import { config } from '../../Config'

import AppUtils from '../../Utilities/AppUtils';
import AuthLib from '../../Utilities/AuthLib';

import { Table, Spinner } from 'react-bootstrap';
import RequestsModal from '../../Components/RequestsModal';

class RequestsList extends Component {

  constructor(props){
    super(props)

    this.state = {
      modalShow: false,
      is_loading: this.props.is_loading,
      request: {}
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({is_loading:nextProps.is_loading})
  }

  modalClose = () => {
    this.setState({ modalShow: false });
    this.setState({ request: false });
  }

  async editRequest(id){
    const auth = await AuthLib.checkAuth();
    // If the user has a valid token, he can see the informations. Otherwise he will be kicked
    if(auth === true){
      this.setState({ modalShow: true })
      const response = await fetch(config.APIUrl + 'API/requests/' + id, {
        method: 'GET',
        headers: {
          'content-type' : 'application/json; charset=utf-8',
          'Authorization' : localStorage.getItem('token')
        },
      });
      const request = await response.json()
      this.setState({ request: request })
  } else{
    // Kick users without authorization
    this.props.history.push('/')
  }

  }

  async deleteRequest(id){
    const reply = window.confirm('Vuoi davvero cancellare questo elemento?')
    reply
    ?
    console.log('Request cancellata')
    :
    console.log('Azione annullata')
  }

  render(){
    return(
      <div>
        <div className="mr-5 ml-2 mb-4">
          <h4 id="leads-primary" className="mt-4">Lista Richieste</h4>
          <h6 className="text-secondary">Richieste provenienti dal form del sito</h6>
        </div>

        <Table className="mr-2 ml-2 responsive">
          <thead>
            <tr>
              <th>Status</th>
              <th>Nome</th>
              <th>Email</th>
              <th>Ragione Sociale</th>
              <th>Data</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.is_loading
              ?
              <tr>
                <td></td>
                <td></td>
                <td>
                  <Spinner className="mx-auto d-block" animation="border" variant="info" />
                </td>
                <td></td>
                <td></td>
              </tr>
                :
                this.props.requests.length > 0 && !this.state.is_loading
                ?
                this.props.requests.map((item, index) => {
                  let date = new Date(item.date);
                  return (
                    <tr key={index}>
                      <td style={{color: AppUtils.checkColor(item.status)}}>{item.status}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.company}</td>
                      <td>{date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}</td>
                      <td>
                        <i onClick={() => this.editRequest(item.id)} id="leads-link" className="fas fa-pen text-secondary"></i>
                        &nbsp; &nbsp;
                        <i onClick={() => this.deleteRequest(item.id)} id="leads-link" className="fas fa-trash text-secondary"></i>
                      </td>
                    </tr>
                  )
                })
                :
                <tr>
                  <td></td>
                  <td className="pt-3">Requests non trovate</td>
                  <td></td>
                </tr>
            }
          </tbody>
        </Table>

        <RequestsModal
          handlerefresh={this.props.handlerefresh}
          show={this.state.modalShow}
          onHide={this.modalClose}
          request={this.state.request}
        />
      </div>
    )
  }

}

export default RequestsList;