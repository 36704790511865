import {config} from '../../Config'

class AuthLib {
  async checkAuth(){
    
    const response = await fetch(config.APIUrl + 'API/auth/verify', {
      method: 'GET',
      headers: {
        'content-type' : 'application/json; charset=utf-8',
        'Authorization' : localStorage.getItem('token')
      }
    })
    .catch(error => console.log(error));

    const success = await response.json();
    return success.success;

  }

}

export default new AuthLib();