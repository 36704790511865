import React, { Component } from 'react';

import { ListGroup } from 'react-bootstrap';

import { withRouter, NavLink } from 'react-router-dom';

class SideNav extends Component {


  state = {
    user_email: ''
  }

  componentDidMount(){
    this.setState({user_email:localStorage.getItem('email')})
  }

  logout(){
    // remove item from localstorage
    localStorage.removeItem('token')
    localStorage.removeItem('email')
    // redirect to auth screen
    this.props.history.push('/')
  }

  render(){

    return(
      <div id="sidenav">
        <ListGroup.Item className="h6 mb-3 pt-4 border-bottom-0">
        <div className="mb-2 text-secondary">ACCOUNT</div>
        {
          this.state.user_email
        }
        </ListGroup.Item>
        <ListGroup>
          <ListGroup.Item className="leads-nav-item p-3">
            <NavLink to="/leads" exact activeClassName="active">
              <i className="far fa-file-alt"></i> Leads 
            </NavLink>
          </ListGroup.Item>
          <ListGroup.Item className="leads-nav-item p-3">
            <NavLink to="/requests" exact activeClassName="active">
              <i className="far fa-file-alt"></i> Requests
            </NavLink>
          </ListGroup.Item>
          <ListGroup.Item className="leads-nav-item p-3" onClick={() => this.logout()}>
            Esci 
          </ListGroup.Item>
        </ListGroup>
      </div>
    )

  }

}

export default withRouter(SideNav);