import React, { Component } from 'react';

import {config} from '../../Config'

import {Modal, Button, Table, Form, Spinner} from 'react-bootstrap';

class VerticalModal extends Component {

  state = {
    lead: {},
    note: '',
    status: '',
    is_loading: false
  }

  componentDidMount(){
    this.setState({lead: this.props.lead})
    this.setState({note: this.props.lead.note})
    this.setState({status: this.props.lead.status})
  }

  componentWillReceiveProps(nextProps){
    this.setState({lead: nextProps.lead})
    this.setState({note: nextProps.lead.note})
    this.setState({status: nextProps.lead.status})
  }

  closeModal = () => {
    this.props.onHide()
  }

  async updateLead(e){
    e.preventDefault();

    this.setState({is_loading: true})
    const response = await fetch(config.APIUrl + 'API/leads/' + this.state.lead.id, {
      method: 'POST',
      headers: {
        'content-type' : 'application/json; charset=utf-8',
        'Authorization' : localStorage.getItem('token')
      },
      body: JSON.stringify({status: this.state.status, note: this.state.note})
    });
    const success = await response.json();
    if(success.success === 'Lead updated') {
      this.props.onHide()
      this.props.handlerefresh()
    }
    this.setState({is_loading: false})
  }

  render() {
    const lead = this.state.lead
    let date = new Date(lead.date);
    return(
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {lead.email}
          </Modal.Title>
        </Modal.Header>
        {
          !lead.email
          ?
          <Spinner className="mx-auto d-block" animation="border" variant="info" />
          :
          <Modal.Body>
            <Form>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label className="leads-primary-text">Status</Form.Label>
                <Form.Control as="select" value={this.state.status} onChange={ e => this.setState({status: e.target.value}) }>
                  <option>pending</option>
                  <option>in gestione</option>
                  <option>attivato</option>
                  <option>sospeso</option>
                </Form.Control>
              </Form.Group>
            </Form>

            <h5 className="leads-primary-text">Dati</h5>
            <Table className="responsive">
              <thead>
                <tr>
                  <th>isMobile</th>
                  <th>Email</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{lead.is_mobile}</td>
                  <td>{lead.email}</td>
                  <td>{date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}</td>
                </tr>
              </tbody>
            </Table>

            <Form>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="leads-primary-text">Richiesta</Form.Label>
                <Form.Control disabled as="textarea" rows="6" value={this.state.lead.request} />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="leads-primary-text">Note</Form.Label>
                <Form.Control as="textarea" rows="6" value={this.state.note} onChange={(e) => this.setState({note: e.target.value})} />
              </Form.Group>
            </Form>

          </Modal.Body>
        }
          <Modal.Footer>
            <Button onClick={e=>this.updateLead(e)}>Aggiorna</Button>
            <Button className="btn-danger" onClick={this.closeModal}>Chiudi</Button>
          </Modal.Footer>
      </Modal>
    );
  }
}

export default VerticalModal;