class AppUtils {

  checkColor(item){
    // Check the state to retrieve the right color
    this.item = item;
    var color = '';

    switch(this.item){
      case 'pending':
        color = '#cccc38';
        break;
      case 'in gestione':
        color = 'blue';
        break;
      case 'attivato':
        color = 'green';
        break;
      case 'sospeso':
        color = 'red';
        break;
      default:
        color = 'black';
        break;
    }
    return color;
  }

}

export default new AppUtils;