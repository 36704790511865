import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

// import screens
import AuthScreen from './Screens/Auth'
import LeadsScreen from './Screens/Leads'
import RequestScreen from './Screens/RequestScreen'

class App extends Component {

  render(){

    return(
      <Router>
        <div>
          <Route path="/" exact component={AuthScreen} />
          <Route path="/leads" component={LeadsScreen} />
          <Route path="/requests" component={RequestScreen} />
        </div>
      </Router>
      )

  }

}

export default App;
